<template>
  <pdf
    src="/files/DaizyWebhookDocument.pdf"
    v-for="i in 18"
    :key="i"
    :page="i"
  ></pdf>
</template>

<script>
import pdf from "vue3-pdf";
export default {
  components: {
    pdf,
  },
};
</script>

<style></style>
